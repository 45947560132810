define("discourse/plugins/discourse-ai/discourse/components/ai-topic-gist", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGist extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "gists", [_service.service]))();
    #gists = (() => (dt7948.i(this, "gists"), void 0))();
    get shouldShow() {
      return this.gists.preference === "table-ai" && this.gists.shouldShow;
    }
    get hasGist() {
      return !!this.gist;
    }
    get gist() {
      return this.args.topic.get("ai_topic_gist");
    }
    get escapedExceprt() {
      return this.args.topic.get("escapedExcerpt");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShow}}
          {{#if this.hasGist}}
            <div class="excerpt">
              <div>{{this.gist}}</div>
            </div>
          {{else}}
            {{#if this.esacpedExceprt}}
              <div class="excerpt">
                <div>{{htmlSafe this.escapedExceprt}}</div>
              </div>
            {{/if}}
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "lbgypqbb",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShow\"]],[[[41,[30,0,[\"hasGist\"]],[[[1,\"        \"],[10,0],[14,0,\"excerpt\"],[12],[1,\"\\n          \"],[10,0],[12],[1,[30,0,[\"gist\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"esacpedExceprt\"]],[[[1,\"          \"],[10,0],[14,0,\"excerpt\"],[12],[1,\"\\n            \"],[10,0],[12],[1,[28,[32,0],[[30,0,[\"escapedExceprt\"]]],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-topic-gist.js",
      "scope": () => [_template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGist;
});